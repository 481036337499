import {defineStore} from 'pinia';
import {CreditsService} from '~/lib/services/credits.service';

export const useCreditsStore = defineStore('credits', {
  state: () => ({
    credits: [],
    isLoading: false,
    error: null,
    creditsService: new CreditsService(),
    subscription: null,
    isSubscribed: false
  }),

  getters: {
    availableCredits: state => {
      return state.creditsService.calculateAvailableCredits(state.credits);
    },

    totalCredits: state => {
      return state.creditsService.calculateTotalCredits(state.credits);
    },

    usedCredits: state => {
      const total = state.creditsService.calculateTotalCredits(state.credits);
      const available = state.creditsService.calculateAvailableCredits(state.credits);
      return total - available;
    },

    hasActiveSubscription: state => {
      return state.creditsService.hasActiveSubscription(state.credits);
    },

    currentSubscription: state => {
      return state.creditsService.getCurrentSubscription(state.credits);
    },

    creditHistory: state => {
      return state.creditsService.getCreditHistory(state.credits);
    },

    currentSubscriptionWithDetails: state => {
      return state.creditsService.getCurrentSubscriptionWithDetails(state.credits);
    },

    currentPlan: state => {
      const subscription = state.creditsService.getCurrentSubscriptionWithDetails(state.credits);
      if (!subscription?.paddle_product_id) return null;

      // If no match found, return the product ID as fallback
      return subscription.paddle_product_id;
    },

    subscriptionPeriod: state => {
      const subscription = state.creditsService.getCurrentSubscriptionWithDetails(state.credits);
      return subscription?.product?.period || null;
    }
  },

  actions: {
    async subscribeToCredits() {
      if (this.isSubscribed) {
        return;
      }

      try {
        // Clean up any existing subscription
        if (this.subscription) {
          await this.creditsService.unsubscribeFromCredits(this.subscription);
          this.subscription = null;
        }

        this.subscription = await this.creditsService.subscribeToCredits(updatedCredits => {
          this.credits = updatedCredits;
        });

        this.isSubscribed = true;
      } catch (error) {
        console.error('Error subscribing to credits updates:', error);
        this.isSubscribed = false;
        this.subscription = null;
      }
    },

    async unsubscribeFromCredits() {
      if (!this.isSubscribed || !this.subscription) {
        return;
      }

      try {
        await this.creditsService.unsubscribeFromCredits(this.subscription);
        this.subscription = null;
        this.isSubscribed = false;
      } catch (error) {
        console.error('Error unsubscribing from credits updates:', error);
      }
    },

    async fetchCredits() {
      this.isLoading = true;
      this.error = null;

      try {
        const {data, error} = await this.creditsService.fetchCredits();
        if (error) throw error;

        this.credits = data;

        // Ensure subscription is active after fetching
        await this.subscribeToCredits();
      } catch (error) {
        console.error('Error fetching credits:', error);
        this.error = error;
      } finally {
        this.isLoading = false;
      }
    },

    async getCustomerPortalUrl() {
      try {
        const {data, error} = await this.creditsService.getCustomerPortalUrl();
        if (error) throw error;

        return data.url;
      } catch (error) {
        console.error('Error getting customer portal URL:', error);
        throw error;
      }
    },

    async updateSubscription(subscription_id, price_id) {
      try {
        const {data, error} = await this.creditsService.updateSubscription(
          subscription_id,
          price_id
        );
        if (error) throw error;

        return data;
      } catch (error) {
        console.error('Error updating subscription:', error);
        throw error;
      }
    },

    async cancelSubscription() {
      try {
        const {data, error} = await this.creditsService.cancelSubscription();
        if (error) throw error;

        return data;
      } catch (error) {
        console.error('Error canceling subscription:', error);
        throw error;
      }
    }
  }
});
